import React from 'react';

const Certification = () => {
    return (
        <div className='bg-grey2 p-2 px-2 my-4 d-flex flex-row'>

            <img src={process.env.PUBLIC_URL+`/ISO.png`} className='w-120'/>
            <h6 className='text-primary my-auto sans-bold'>Высокое качество деятельности организации подтверждается наличием сертификата соответствия ИСО 9001-2015</h6>
        </div>
    );
};

export default Certification;