import React from 'react';
import {Button} from "react-bootstrap";
import OrderButton from "../components/OrderButton";
import {IProduct} from "../util";

const Ktpb: React.FC<IProduct> = ({setSrc, setShowOrderWindow}) => {

    const prod = 'ktpb'

    return (
        <div id={`${prod}`} className='bg-grey2 p-4 my-2'>
            <h4 className='text-primary'>КОМПЛЕКТНЫЕ ТРАНСФОРМАТОРНЫЕ ПОДСТАНЦИИ БЛОЧНЫЕ КТПБ</h4>

            <p className='text-primary sans-bold'>Назначение</p>
            <p className=''>КТПБ предназначены для приема, преобразования и распределения электрической энергии трехфазного переменного тока частотой 50Гц, на класс напряжения 35-110 кВ.</p>

            <div className='d-flex my-2 flex-md-row flex-column'>
                <a onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert.pdf`)} download={'Сертификат'} className='text-primary link-dark link-underline-opacity-0'>
                    Сертификат
                </a>
                <a href='#' className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Опросный лист
                </a>
                <a href={`#tech-${prod}`} className='text-primary link-dark link-underline-opacity-0'>
                    Техническая информация
                </a>
                <a onClick={() => setSrc(process.env.PUBLIC_URL+`/${prod}/cu.pdf`)} download={'Условия эксплуатации'} className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Условия эксплуатации
                </a>
            </div>

            <div className='d-flex flex-md-row flex-column p-md-5 justify-content-between'>
                <img src={process.env.PUBLIC_URL+`/${prod}/img.png`} className='w-md-40p w-100 mx-auto'/>
                <div className='mx-5 my-auto'>
                    <p className='text-primary sans-bold'>Область применения</p>
                    <p className=''>Используется для электроснабжения промышленных и коммунальных потребителей, сельскохозяйственных районов и крупных строительств.</p>
                </div>
            </div>

            <p className='bg-success rounded rounded-2 p-1'>КТПБ изменяется в соответствии с индивидуальными требованиями заказчика</p>

            <p id={`tech-${prod}`} className='text-primary sans-bold'>Технические характеристики</p>
            <table className={'w-100'}>
                <tbody>
                    <tr className='border-bottom'>
                        <td>Номинальное напряжение, кВ</td>
                        <td>35</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Мощность силового трансформатора, кВА</td>
                        <td>до 63 000</td>
                    </tr>
                    <tr className=''>
                        <td>Номинальный ток, А:</td>

                    </tr>
                    <tr className='border-bottom'>
                        <td>цепей силовых трансформаторов;</td>
                        <td>до 2000</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>главных цепей;</td>
                        <td>до 2000</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>сборных шин;</td>
                        <td>до 2000</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Ударный ток короткого замыкания (амплитуда), кА</td>
                        <td>до 31,5</td>
                    </tr>
                    <tr className=''>
                        <td>Ток термической стойкости в течении 3с, кА</td>
                        <td>до 31,5</td>
                    </tr>
                </tbody>
            </table>

            <div className='d-flex justify-content-center'><OrderButton setShowOrderWindow={setShowOrderWindow}/></div>

        </div>
    );
};

export default Ktpb;