import React from 'react';
import {Button, Dropdown} from "react-bootstrap";
import OrderButton from "../components/OrderButton";
import {IProduct} from "../util";

const Kso: React.FC<IProduct> = ({setSrc, setShowOrderWindow}) => {

    const prod = 'kso'

    return (
        <div id={`${prod}`} className='bg-grey2 p-4 my-4'>
            <h4 className='text-primary'>КАМЕРЫ СБОРНЫЕ ОДНОСТОРОННЕГО ОБСЛУЖИВАНИЯ КСО-298, КСО-366</h4>

            <p className='text-primary sans-bold'>Назначение</p>
            <p className=''>КСО-298, КСО-366 предназначены для приема и распределения электроэнергии трехфазного переменного тока частотой 50 Гц напряжением 6 – 10 кВ в сетях с изолированной или заземленной через дугогасящий реактор нейтралью.</p>

            <div className='d-flex my-2 flex-md-row flex-column'>
                <Dropdown>
                    <Dropdown.Toggle variant="primary">
                        Сертификаты
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert-kso-298.pdf`)} download={'Условия эксплуатации КСО-298'}>КСО-298</Dropdown.Item>
                        <Dropdown.Item onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert-kso-366.pdf`)} download={'Условия эксплуатации КСО-366'}>КСО-366</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <a href='#' className='text-primary link-dark link-underline-opacity-0 mx-md-5 py-2'>
                    Опросный лист
                </a>
                <a href={`#tech-${prod}`} className='text-primary link-dark link-underline-opacity-0 py-2'>
                    Техническая информация
                </a>
                <a onClick={() => setSrc(process.env.PUBLIC_URL+`/${prod}/cu.pdf`)} download={'Условия эксплуатации'}
                   className='text-primary link-dark link-underline-opacity-0 mx-md-5 py-2'>
                    Условия эксплуатации
                </a>
            </div>

            <div className='d-flex flex-md-row flex-column p-md-5 justify-content-between'>
                <img src={process.env.PUBLIC_URL+`/${prod}/img.png`} className='w-md-25p w-75  mx-auto'/>
                <div className='mx-5 my-auto'>
                    <p className='text-primary sans-bold'>Область применения</p>
                    <p className=''>Применяются в составе РУ напряжением 6 – 10 кВ при новом строительстве, расширении, реконструкции и техническом перевооружении следующих объектов:</p>
                    <ul className=''>
                        <li>распределительных и трансформаторных подстанций городских электрических сетей;</li>
                        <li>распределительных и трансформаторных подстанций объектов гражданского назначения и инфраструктуры;</li>
                        <li>распределительных подстанций предприятий легкой промышленности;</li>
                        <li>тяговых подстанций городского электрического транспорта и метрополитена, ТП 6-10/0,4 кВ и распределительных сетей.</li>
                    </ul>
                </div>
            </div>

            <div className='bg-success rounded rounded-2 p-1 my-2'>
                <p>Установленный стационарно ВВ имеет коммутационный ресурс до 50 000 циклов, что позволяет не использовать сложные выкатные элементы.</p>
                <p className='m-0'>При необходимости установки КСО в помещениях с температурой окружающего воздуха ниже -25°С, предусматривается размещение в камерах нагревательных элементов, обеспечивающих нормальные температурные условия работы аппаратуры.</p>
            </div>

            <p id={`tech-${prod}`} className='text-primary sans-bold'>Технические характеристики</p>
            <table className={'w-100'}>
                <tbody>
                <tr className='border-bottom'>
                    <td>Номинальное напряжение, кВ</td>
                    <td>6; 10</td>
                </tr>
                <tr className='border-bottom'>
                    <td>Наибольшее рабочее напряжение, кВ</td>
                    <td>7,2; 12</td>
                </tr>
                <tr className=''>
                    <td>Номинальный ток, А:</td>
                </tr>
                <tr className='border-bottom'>
                    <td>сборных шин</td>
                    <td>630; 1000</td>
                </tr>
                <tr className='border-bottom'>
                    <td>главных цепей</td>
                    <td>400; 630; 1000</td>
                </tr>
                <tr className='border-bottom'>
                    <td>Номинальный ток отключения выключателя, кА</td>
                    <td>630</td>
                </tr>
                <tr className='border-bottom'>
                    <td>Номинальный ток термической стойкости (3 сек), кА</td>
                    <td>20</td>
                </tr>
                <tr className=''>
                    <td>Ток термической стойкости, кА</td>
                    <td>51</td>
                </tr>
                </tbody>
            </table>

            <div className='d-flex justify-content-center'><OrderButton setShowOrderWindow={setShowOrderWindow}/></div>

        </div>
    );
};

export default Kso;