import React, {useState} from 'react';

const Navbar = () => {

    const [show, setShow] = useState(false)

    return (
        <div className='d-flex flex-column flex-md-row justify-content-between pt-2 px-4'>
            <img className='d-none d-md-flex w-180 mx-4' src={process.env.PUBLIC_URL + '/logo.png'}/>
            <button type="button" className="btn btn-outline-secondary h-50 d-flex d-md-none w-40 mb-2"
                    onClick={() => setShow(!show)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-justify" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                          d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </button>
            <div className={`${show ? 'flex-column d-flex' : 'd-none'} d-md-flex flex-md-row justify-content-around my-auto`}>
                <a href='#aboutUs' className='border-bottom-success link-dark link-underline-opacity-0 h-25'>О компании</a>
                <a href='#production' className='mx-md-3 border-bottom-success link-dark link-underline-opacity-0 h-25'>Продукция</a>
                <a href='#career'  className='border-bottom-success link-dark link-underline-opacity-0 h-25'>Карьера</a>
                <a href='#contacts' className='mx-md-3 border-bottom-success link-dark link-underline-opacity-0 h-25 mb-2'>Контакты</a>
            </div>
        </div>
    );
};

export default Navbar;