import React from 'react';

const Contacts = () => {
    return (
        <div id='contacts' className='bg-grey4 p-4 d-flex flex-md-row flex-column justify-content-between'>
            <div>
                <h5 className='text-white'>ООО "НТЦ ЭЛЕКТРОАППАРАТ"</h5>
                <h6 className='text-white'>ЮРИДИЧЕСКИЙ АДРЕС:</h6>
                <p className='text-white'>
                    443022, Самарская область, г.о. Самара, вн. р-н<br/>Промышленный, г. Самара, пр-кт Кирова, д.10
                </p>
                <h6 className='text-white'>ПРОИЗВОДСТВЕННАЯ ПЛОЩАДКА:</h6>
                <p className='text-white'>
                    Самарская область, г. Самара, проспект Кирова, 10
                </p>
            </div>
            <div className=''>
                <h5 className='text-white'>КОНТАКТЫ</h5>
                <p className='text-white m-0'>+7 (846) 302-70-90</p>
                <p className='text-white m-0'>info@ntc-ea.ru</p>
            </div>
            <div className='d-flex flex-column mt-2'>
                <h5 className='text-white'>ПРОДУКЦИЯ</h5>
                <a href='#ktpb' className='text-white link-dark link-underline-opacity-0'>КТПБ 35/110 кВ</a>
                <a href='#kso' className='text-white link-dark link-underline-opacity-0'>КСО-298, КСО-366 кВ</a>
                <a href='#ktpn' className='text-white link-dark link-underline-opacity-0'>КТП наружной установки 6, 10 кВ</a>
                <a href='#bktp' className='text-white link-dark link-underline-opacity-0'>БКТП 6,10 кВ</a>
                <a href='#nku' className='text-white link-dark link-underline-opacity-0'>НКУ 0,4 кВ</a>
                <a href='#mab' className='text-white link-dark link-underline-opacity-0'>МЭБ</a>
            </div>
        </div>
    );
};

export default Contacts;