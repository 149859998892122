import React from 'react';
import {Button} from "react-bootstrap";
import OrderButton from "../components/OrderButton";
import {IProduct} from "../util";

const Nku: React.FC<IProduct> = ({setSrc, setShowOrderWindow}) => {

    const prod = 'nku'

    return (
        <div id={`${prod}`} className='bg-grey2 p-4 my-4'>
            <h4 className='text-primary'>НИЗКОВОЛЬТНЫЕ КОМПЛЕКТНЫЕ УСТРОЙСТВА НКУ</h4>

            <p className='text-primary sans-bold'>Назначение</p>
            <p className=''>НКУ предназначены для комплектования щитов распределения электроэнергии трехфазного переменного тока напряжением 380/220 В частотой 50 Гц в сетях с изолированной или глухо заземлённой нейтралью. Применяются для приема и распределения электроэнергии, защиты от перегрузок и токов короткого замыкания в четырехпроводных и пяти проводных распределительных сетях, устанавливаются в электропомещениях.</p>

            <div className='d-flex my-2 flex-md-row flex-column'>
                <a onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert.pdf`)} download={'Сертификат'} className='text-primary link-dark link-underline-opacity-0'>
                    Сертификат
                </a>
                <a href='#' className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Опросный лист
                </a>
                <a href={`#tech-${prod}`} className='text-primary link-dark link-underline-opacity-0'>
                    Техническая информация
                </a>
                <a onClick={() => setSrc(process.env.PUBLIC_URL+`/${prod}/cu.pdf`)} download={'Условия эксплуатации'} className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Условия эксплуатации
                </a>
            </div>

            <div className='d-flex flex-md-row flex-column p-md-5 justify-content-between'>
                <div className='mx-5 my-auto'>
                    <p className='text-primary sans-bold'>Область применения</p>
                    <p className='m-0'>Предназначено для применения на средних и крупных промышленных предприятиях, объектах гражданского строительства и инфраструктуры, таких как:</p>
                    <p className='m-0'>-нефтегазовая и нефтехимическая отрасли;</p>
                    <p className='m-0'>-шахты, металлургия, производство цемента;</p>
                    <p className='m-0'>-пищевая промышленность, обрабатывающая промышленность;</p>
                    <p className='m-0'>-объекты инфраструктуры, туннели, железные дороги;</p>
                    <p className='m-0'>-водоподготовка, водоснабжение и т.д.</p>
                </div>
                <img src={process.env.PUBLIC_URL+`/${prod}/img.png`} className='w-md-40p w-100 mx-auto' alt={''}/>
            </div>

            <div className='bg-success rounded rounded-2 p-1 my-2'>
                <p className='sans-bold m-0 text-primary'>Преимущества</p>
                <ul className=''>
                    <li>компактный универсальный корпус;</li>
                    <li>свобода в выборе электрических компонентов;</li>
                    <li>разнообразие схем позволяет применять различные технические решения для каждого конкретного объекта;</li>
                    <li>возможность использования любых коммутационных аппаратов: выкатные, втычные, стационарные;</li>
                    <li>возможность расширения устройства путем добавления секций при увеличении числа приемников электрической энергии.</li>
                </ul>
            </div>

            <p id={`tech-${prod}`} className='text-primary sans-bold'>Технические характеристики</p>
            <table className={'w-100'}>
                <tbody>
                    <tr className='border-bottom'>
                        <td>Номинальное рабочее напряжение главных цепей, В</td>
                        <td>380</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное рабочее напряжение цепей управления, В</td>
                        <td>220</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Частота, Гц</td>
                        <td>50</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Число отходящих линий, шт</td>
                        <td>1, 2, 4, 6</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальный ток вводных панелей, А</td>
                        <td>400 - 2000</td>
                    </tr>
                    <tr className=''>
                        <td>Номинальный ток отходящих линий, А</td>
                        <td>100-1600</td>
                    </tr>
                </tbody>
            </table>

            <div className='d-flex justify-content-center mt-3'><OrderButton setShowOrderWindow={setShowOrderWindow}/></div>

        </div>
    );
};

export default Nku;