import React from 'react';
import {Button} from "react-bootstrap";

const ProductionMenu = () => {
    return (
        <div  id='production' className='my-5'>
            <h4 className='text-center my-3 text-primary sans-bold'>ПРОДУКЦИЯ</h4>
            <div className='d-flex flex-md-row flex-column justify-content-md-around mb-3'>
                <div className='d-flex flex-column mx-auto'>
                    <a href='#ktpb' className='link-dark link-underline-opacity-0 my-4'>
                        <Button variant='success' className='w-300 sans-bold'>
                            КТПБ 35/110 кВ
                        </Button>
                    </a>
                    <a href='#kso' className='link-dark link-underline-opacity-0'>
                        <Button variant='success'  className='w-300 sans-bold'>
                            КСО-298, КСО-366 10 кВ
                        </Button>
                    </a>
                </div>
                <div className='d-flex flex-column mx-auto'>
                    <a href='#ktpb' className='link-dark link-underline-opacity-0 my-4'>
                        <Button variant='success' className='w-300 sans-bold'>
                            КТП наружной установки 6, 10 кВ
                        </Button>
                    </a>
                    <a href='#kso' className='link-dark link-underline-opacity-0'>
                        <Button variant='success'  className='w-300 sans-bold'>
                            БКТП 6, 10 кВ
                        </Button>
                    </a>
                </div>
                <div className='d-flex flex-column mx-auto'>
                    <a href='#ktpb' className='link-dark link-underline-opacity-0 my-4'>
                        <Button variant='success' className='w-300 sans-bold'>
                            НКУ 0,4 кВ
                        </Button>
                    </a>
                    <a href='#mab' className='link-dark link-underline-opacity-0'>
                        <Button variant='success'  className='w-300 sans-bold'>
                            Блок Модуль
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProductionMenu;