import React from 'react';

const AboutUs = () => {
    return (
        <div id='aboutUs' className='bg-grey2 p-4'>
            <p>
                <span className='text-primary'>ООО "НТЦ ЭЛЕКТРОАППАРАТ"</span> - это современная, стремительно
                развивающаяся, оснащенная самым современным оборудованием компания, осуществляющая производство,
                продажу и поставку электротехнической продукции высокого качества в максимально сжатые сроки.
            </p>
            <p className='bg-success rounded rounded-2 p-1'>Осуществляем поставку оборудования повышенной заводской готовности</p>
            <p>
                Мы гарантируем надёжность и эксплуатационную безопасность выпускаемой продукции.
                Соблюдение требований технологического процесса обеспечивает необходимый уровень качества продукции
                на всех этапах производства.
            </p>

            <ul className='ul-check p-0 px-3'>
                <li><span>проведение испытаний при постановке продукции в серийное производство</span></li>
                <li><span>входной контроль материалов и комлектующих изделий</span></li>
                <li><span>контроль процесса запуска изделий в производство</span></li>
                <li><span>контроль изготовления оснастки и инструмента</span></li>
                <li><span>контроль на стадии упаковки, хранения и отгрузки продукции</span></li>
                <li><span>системный контроль готовых изделий</span></li>
            </ul>
        </div>
    );
};

export default AboutUs;