import React, {useEffect, useState} from 'react';
import TopPanel from "./blocks/TopPanel";
import Navbar from "./blocks/Navbar";
import ScrollToTopButton from "./components/ScrollToTopButton";
import CarouselComponent from "./blocks/CarouselComponent";
import AboutUs from './blocks/AboutUs';
import ProductionMenu from './blocks/ProductionMenu';
import Career from "./blocks/Career";
import Contacts from "./blocks/Contacts";
import Certification from "./blocks/Certification";
import Ktpb from "./production/Ktpb";
import Kso from "./production/Kso";
import Ktpn from "./production/Ktpn";
import Bktp from "./production/Bktp";
import Nku from "./production/Nku";
import Mab from "./production/Mab";
import OrderWindow from "./components/OrderWindow";
import FileReader from "./components/FileReader";

const App = () => {

    const [src, setSrc] = useState('')
    const [showFileReader, setShowFileReader] = useState(false)
    const [showOrderWindow, setShowOrderWindow] = useState(false)

    useEffect(() => {
        if (src !== '') {
            setShowFileReader(true)
        }
    }, [src]);

    return (
        <div>
            <div
                // className='sticky-top bg-white'
            >
                <TopPanel/>
                <Navbar/>
            </div>

            <FileReader src={src} show={showFileReader} setShow={setShowFileReader} setSrc={setSrc}/>
            <OrderWindow show={showOrderWindow} setShow={setShowOrderWindow}/>

            <CarouselComponent setShowOrderWindow={setShowOrderWindow}/>
            <ProductionMenu/>
            <AboutUs/>
            <Certification/>

            <Ktpb setSrc={setSrc} setShowOrderWindow={setShowOrderWindow}/>
            <Kso setSrc={setSrc} setShowOrderWindow={setShowOrderWindow}/>
            <Ktpn setSrc={setSrc} setShowOrderWindow={setShowOrderWindow}/>
            <Bktp setSrc={setSrc} setShowOrderWindow={setShowOrderWindow}/>
            <Nku setSrc={setSrc} setShowOrderWindow={setShowOrderWindow}/>
            <Mab setSrc={setSrc} setShowOrderWindow={setShowOrderWindow}/>

            <Career/>
            <Contacts/>
            <ScrollToTopButton/>
        </div>
    );
}

export default App;
