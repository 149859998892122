import React from 'react';
import {Button, Dropdown} from "react-bootstrap";
import OrderButton from "../components/OrderButton";
import {IProduct} from "../util";

const Mab: React.FC<IProduct> = ({setSrc, setShowOrderWindow}) => {

    const prod = 'mab'

    return (
        <div id={`${prod}`} className='bg-grey2 p-4 my-4'>
            <h4 className='text-primary'>БЛОК МОДУЛЬ</h4>

            <p className='text-primary sans-bold'>Назначение</p>
            <p className=''>Блок-модуль предназначен для внутреннего размещения электротехнического оборудования и защиты этого оборудования и обслуживающего персонала от воздействия внешней среды.</p>

            <div className='d-flex my-2 flex-md-row flex-column'>
                <Dropdown>
                    <Dropdown.Toggle variant="primary">
                        Сертификаты
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert.pdf`)} download={'Сертификат соответствия'}>Сертификат соответствия</Dropdown.Item>
                        <Dropdown.Item onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert-p.pdf`)} download={'Пожарный сертификат'}>Пожарный сертификат</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <a href='#' className='text-primary link-dark link-underline-opacity-0 mx-md-5 py-2'>
                    Опросный лист
                </a>
                <a href={`#tech-${prod}`} className='text-primary link-dark link-underline-opacity-0 py-2'>
                    Техническая информация
                </a>
                <a onClick={() => setSrc(process.env.PUBLIC_URL+`/${prod}/cu.pdf`)} download={'Условия эксплуатации'}
                   className='text-primary link-dark link-underline-opacity-0 mx-md-5 py-2'>
                    Условия эксплуатации
                </a>
            </div>

            <div className='d-flex flex-md-row flex-column p-md-5 justify-content-between'>
                <div className='mx-5 my-auto'>
                    <p className='text-primary sans-bold'>Область применения</p>
                    <p className='m-0'>Применяется для электроснабжения сельскохозяйственных и городских объектов, отдельных населенных пунктов и промышленных объектов.</p>
                </div>
                <img src={process.env.PUBLIC_URL+`/${prod}/img.png`} className='w-md-40p w-100 mx-auto' alt={''}/>
            </div>

            <p id={`tech-${prod}`} className='text-primary sans-bold'>Технические характеристики</p>
            <p className='my-2 fw-normal'>Массогабаритные характеристики блоков с <span>продольно-радиальной крышей</span> для размещения электрооборудования</p>
            <table className={'w-100 t1'}>
                <tbody>
                    <tr>
                        <th>Длинна, мм</th>
                        <th>Ширина, мм</th>
                        <th>Высота, мм</th>
                        <th>Масса блока без оборудования, мм</th>
                    </tr>
                    <tr>
                        <td>7500</td>
                        <td>2400/3000</td>
                        <td>3755</td>
                        <td>4,5/5,6</td>
                    </tr>
                    <tr>
                        <td>4500</td>
                        <td>2400/3000</td>
                        <td>3520</td>
                        <td>3,5/4</td>
                    </tr>
                    <tr>
                        <td>8000</td>
                        <td>2400/3000</td>
                        <td>3800</td>
                        <td>4,8/6</td>
                    </tr>
                </tbody>
            </table>


            <p className='my-2 fw-normal'>Массогабаритные характеристики блоков с <span>поперечно-радиальной</span> крышей для размещения электрооборудования</p>
            <table className={'w-100 t1'}>
                <tbody>
                    <tr>
                        <th>Длинна, мм</th>
                        <th>Ширина, мм</th>
                        <th>Высота, мм</th>
                        <th>Масса блока без оборудования, мм</th>
                    </tr>
                    <tr>
                        <td>7500</td>
                        <td>2400/3000</td>
                        <td>3585</td>
                        <td>4,5/5,6</td>
                    </tr>
                    <tr>
                        <td>4500</td>
                        <td>2400/3000</td>
                        <td>3585</td>
                        <td>3,5/4</td>
                    </tr>
                    <tr>
                        <td>8000</td>
                        <td>2400/3000</td>
                        <td>3585</td>
                        <td>4,8/6</td>
                    </tr>
                </tbody>
            </table>

            <div className='d-flex justify-content-center mt-3'><OrderButton setShowOrderWindow={setShowOrderWindow}/></div>

        </div>
    );
};

export default Mab;