import React from 'react';
import {Button} from "react-bootstrap";

interface IOrderButton {
    setShowOrderWindow: (s: boolean) => void
}

const OrderButton: React.FC<IOrderButton> = ({setShowOrderWindow}) => {
    return (
        <Button
            variant='success'
            className='text-white fw-medium my-3'
            onClick={() => {
                setShowOrderWindow(true)
            }}
        >
            ЗАКАЗАТЬ РАСЧЕТ
        </Button>
    );
};

export default OrderButton;