import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface IFileReader {
    src: string
    setSrc: (src: string) => void
    show: boolean
    setShow: (b: boolean) => void
}

const FileReader: React.FC<IFileReader> = ({src, setSrc, show, setShow}) => {

    const handleClose = () => {
        setShow(false)
        setSrc('')
    }

    return (
        <Modal centered dialogClassName="my-modal" show={show} onHide={handleClose}>
            <Modal.Header>
                <Button variant="danger" onClick={handleClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </Button>
            </Modal.Header>
            <Modal.Body className={'m-0 p-0 overflow-y-auto mh-300'}>
                <embed src={`${src}`} type="application/pdf" width="99%" height="800px" />
            </Modal.Body>
        </Modal>
    );
};

export default FileReader;