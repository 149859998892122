import React from 'react';
import {Button, Carousel} from "react-bootstrap";
import OrderButton from "../components/OrderButton";

interface ICarouselComponent {
    setShowOrderWindow: (s: boolean) => void
}

const CarouselComponent: React.FC<ICarouselComponent> = ({setShowOrderWindow}) => {

    const orderButton = (
        <div className='d-flex justify-content-center'>
            <OrderButton setShowOrderWindow={setShowOrderWindow}/>
        </div>
    )

    return (
        <Carousel
            indicators={false}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/slider/bg.png)`,
                backgroundAttachment: 'fixed',
                padding: '10px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                margin: '0px 50px 0px 50px'
            }} >

            <Carousel.Item>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-5'><img src={process.env.PUBLIC_URL+`/slider/bktp.png`} className="w-100 p-5" alt=""/></div>
                    <div className='col-md-5 my-auto'>
                        <h4 className='mt-3 text-white text-center'>БЕТОННЫЕ КОМПЛЕКТНЫЕ ТРАНСФОРМАТОРНЫЕ ПОДСТАНЦИИ БКТП-НТЦЭА</h4>
                        {orderButton}
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-5'><img src={process.env.PUBLIC_URL+`/slider/ktpn.png`} className="w-100 p-5" alt=""/></div>
                    <div className='col-md-5 my-auto'>
                        <h4 className='mt-3 text-white text-center'>КОМПЛЕКТНЫЕ ТРАНСФОРМАТОРНЫЕ ПОДСТАНЦИИ НАРУЖНОГО ИСПОЛНЕНИЯ КТПН</h4>
                        {orderButton}
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-5'><img src={process.env.PUBLIC_URL+`/slider/kso.png`} className="w-100 p-5" alt=""/></div>
                    <div className='col-md-5 my-auto'>
                        <h4 className='mt-3 text-white text-center'>КАМЕРЫ СБОРНЫЕ ОДНОСТОРОННЕГО ОБСЛУЖИВАНИЯ КСО-298, КСО-366</h4>
                        {orderButton}
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-5'><img src={process.env.PUBLIC_URL+`/slider/nku.png`} className="w-100 p-5" alt=""/></div>
                    <div className='col-md-5 my-auto'>
                        <h4 className='mt-3 text-white text-center'>НИЗКОВОЛЬТНЫЕ КОМПЛЕКТНЫЕ УСТРОЙСТВА НКУ</h4>
                        {orderButton}
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-5'><img src={process.env.PUBLIC_URL+`/slider/mab.png`} className="w-100 p-5" alt=""/></div>
                    <div className='col-md-5 my-auto'>
                        <h4 className='mt-3 text-white text-center'>БЛОК МОДУЛЬ</h4>
                        {orderButton}
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </Carousel.Item>
            
        </Carousel>
    );
};

export default CarouselComponent;