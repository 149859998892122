import React from 'react';
import {Button} from "react-bootstrap";
import OrderButton from "../components/OrderButton";
import {IProduct} from "../util";

const Ktpn: React.FC<IProduct> = ({setSrc, setShowOrderWindow}) => {

    const prod = 'ktpn'

    return (
        <div id={`${prod}`} className='bg-grey2 p-4 my-2'>
            <h4 className='text-primary'>КОМПЛЕКТНЫЕ ТРАНСФОРМАТОРНЫЕ ПОДСТАНЦИИ НАРУЖНОГО ИСПОЛНЕНИЯ КТПН</h4>

            <p className='text-primary sans-bold'>Назначение</p>
            <p className=''>Предназначена для приема, преобразования и распределения электрической энергии трехфазного переменного тока частотой 50 Гц, напряжением 6-10/0,4 кВ.</p>

            <div className='d-flex my-2 flex-md-row flex-column'>
                <a onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert.pdf`)} download={'Сертификат'} className='text-primary link-dark link-underline-opacity-0'>
                    Сертификат
                </a>
                <a href='#' className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Опросный лист
                </a>
                <a href={`#tech-${prod}`} className='text-primary link-dark link-underline-opacity-0'>
                    Техническая информация
                </a>
                <a onClick={() => setSrc(process.env.PUBLIC_URL+`/${prod}/cu.pdf`)} download={'Условия эксплуатации'} className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Условия эксплуатации
                </a>
            </div>

            <div className='d-flex flex-md-row flex-column p-md-5 justify-content-between'>
                <div className='mx-5 my-auto'>
                    <p className='text-primary sans-bold'>Область применения</p>
                    <p className=''>Применяется	для электроснабжения сельскохозяйственных объектов, нефтегазовых месторождений, отдельных населенных пунктов и промышленных объектов.</p>
                </div>
                <img src={process.env.PUBLIC_URL+`/${prod}/img.png`} className='w-md-25p w-100 mx-auto'/>
            </div>

            <div className='bg-success rounded rounded-2 p-1 my-2'>
                <p className='sans-bold m-0'>долговечность и надежность</p>
                <ul className=''>
                    <li>корпус на основе прочного каркаса из металла толщиной не менее 4мм;</li>
                    <li>повышенная коррозионная стойкость;</li>
                    <li>применение в составе современного электротехнического оборудования с высокой эксплуатационной надежностью позволяет гарантировать бесперебойное и качественное электроснабжение не менее 30 лет.</li>
                </ul>
                <p className='sans-bold m-0'>уникальность и компактность</p>
                <ul className=''>
                    <li>минимальные сроки проектирования;</li>
                    <li>максимальная заводская готовность, готовность к вводу в эксплуатацию в течение 1-2 дней после поставки;</li>
                    <li>возможность применения любых типов силовых трансформаторов.</li>
                </ul>
                <p className='sans-bold m-0'>безопасность</p>
                <ul className=''>
                    <li>комплектуется системой охранно-пожарной сигнализации с возможностью подключения к внешним устройствам;</li>
                    <li>механические и электрические блокировки, гарантирующие безопасность обслуживающего персонала.</li>
                </ul>
            </div>

            <p id={`tech-${prod}`} className='text-primary sans-bold'>Технические характеристики</p>
            <table className={'w-100'}>
                <tbody>
                    <tr className='border-bottom'>
                        <td>Мощность силового трансформатора, кВА</td>
                        <td>25-250</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное напряжение на стороне ВН, кВ</td>
                        <td>6, 10</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Максимальное напряжение на стороне ВН, кВ</td>
                        <td>7.2, 12</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное напряжение на стороне НН, кВ</td>
                        <td>0.4</td>
                    </tr>
                    <tr className=''>
                        <td>Степень защиты по ГОСТ 14254-96</td>
                        <td>IP 23</td>
                    </tr>
                </tbody>
            </table>

            <div className='mt-2 d-flex justify-content-center'><OrderButton setShowOrderWindow={setShowOrderWindow}/></div>

        </div>
    );
};

export default Ktpn;