import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import OrderButton from "../components/OrderButton";
import FileReader from "../components/FileReader";
import {IProduct} from "../util";

const Bktp: React.FC<IProduct> = ({setSrc, setShowOrderWindow}) => {

    const prod = 'bktp'

    return (
        <div id={`${prod}`} className='bg-grey2 p-4 my-4'>
            <h4 className='text-primary'>БЕТОННЫЕ КОМЛЕКТНЫЕ ТРАНСФОРМАТОРНЫЕ ПОДСТАНЦИИ БКТП</h4>

            <p className='text-primary sans-bold'>Назначение</p>
            <p className=''>БКТП предназначены для приема, преобразования и распределения электрической энергии трехфазного переменного тока частотой 50 Гц, напряжением 6-10/0,4 кВ.</p>

            <div className='d-flex my-2 flex-md-row flex-column'>
                <a onClick={()=> setSrc(process.env.PUBLIC_URL+`/${prod}/cert.pdf`)} download={'Сертификат'} className='text-primary link-dark link-underline-opacity-0'>
                    Сертификат
                </a>
                <a href='#' className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Опросный лист
                </a>
                <a href={`#tech-${prod}`} className='text-primary link-dark link-underline-opacity-0'>
                    Техническая информация
                </a>
                <a onClick={() => setSrc(process.env.PUBLIC_URL+`/${prod}/cu.pdf`)} download={'Условия эксплуатации'} className='text-primary link-dark link-underline-opacity-0 mx-md-5'>
                    Условия эксплуатации
                </a>
            </div>

            <div className='d-flex flex-md-row flex-column p-md-5 justify-content-between'>
                <div className='mx-5 my-auto'>
                    <p className='text-primary sans-bold'>Область применения</p>
                    <p className=''>Применяется для электроснабжения сельскохозяйственных и городских объектов, отдельных населенных пунктов и промышленных объектов.</p>
                </div>
                <img src={process.env.PUBLIC_URL+`/${prod}/img.png`} className='w-md-40p w-100 mx-auto' alt={''}/>
            </div>

            <p className='bg-success rounded rounded-2 p-1'>БКТП могут содержать шумозащитные средства, что позволяет применять их в черте города</p>

            <p id={`tech-${prod}`} className='text-primary sans-bold'>Технические характеристики</p>
            <table className={'w-100'}>
                <tbody>
                    <tr className='border-bottom'>
                        <td>Номинальная мощность силового трансформатора, кВА</td>
                        <td>160; 250; 400; 630; 100; 1250; 1600; 2500</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное напряжение на стороне ВН, кВ</td>
                        <td>6; 10</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное напряжение на стороне НН, кВ</td>
                        <td>0,4</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное ток сборных шин на стороне ВН, кВ</td>
                        <td>400; 630</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное ток сборных шин на стороне НН, кВ</td>
                        <td>400-5000</td>
                    </tr>
                    <tr className=''>
                        <td>Ток термической стойкости сборных шин на стороне ВН для распределительных устройств (далее – РУ):</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>с воздушной изоляцией, кА/2 с</td>
                        <td>12,5; 20</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>с элегазовой изоляцией, кА/1 с</td>
                        <td>21; 25</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Ток термической стойкости сборных шин на стороне НН, кА/1 с</td>
                        <td>20; 50; 100</td>
                    </tr>

                    <tr className=''>
                        <td>Ток электродинамической стойкости сборных шин на стороне ВН, для РУ:</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>с воздушной изоляцией, кА</td>
                        <td>31,5</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>с элегазовой изоляцией, кА</td>
                        <td>51</td>
                    </tr>

                    <tr className='border-bottom'>
                        <td>Ток электродинамической стойкости сборных шин на стороне НН, кА</td>
                        <td>40; 110; 220;</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное напряжение вспомогательных цепей переменного тока, В</td>
                        <td>220</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Номинальное напряжение освещения, В</td>
                        <td>12</td>
                    </tr>
                    <tr className=''>
                        <td>Степень защиты по ГОСТ 14254</td>
                        <td>IP 23</td>
                    </tr>
                </tbody>
            </table>

            <div className='d-flex justify-content-center mt-3'><OrderButton setShowOrderWindow={setShowOrderWindow}/></div>

        </div>
    );
};

export default Bktp;