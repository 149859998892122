import React from 'react';

const TopPanel = () => {
    return (
        <div className='d-flex flex-column flex-md-row justify-content-end bg-grey3 p-1'>
            <p className='mr-5 mb-0 text-primary'>+7 (846) 205-19-05</p>
            <p className='mr-5 mb-0 text-primary'>info@ntc-ea.ru</p>
        </div>
    );
};

export default TopPanel;