import React, {useState} from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputMask from 'react-input-mask';
import emailjs from "emailjs-com";


interface ModalWindowProps {
    show: boolean
    setShow: (b: boolean) => void
}

const OrderWindow: React.FC<ModalWindowProps> = ({ show, setShow}) => {

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [permission, setPermission] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    function validateEmail(email: string): boolean {
        if (email === '') return true
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function onSubmit() {
        if (!permission) {
            alert('Необходимо дать согласие на обработку персональных данных')
        }
        if (!validateEmail(email)) {
            alert('Введите корректный email')
        }
        sendEmail()

        setEmail("")
        setPhone("")
        setName("")
        setPermission(false)
    }

    const sendEmail = () => {
        emailjs
            .send(
                "service_swf9fxq",
                "template_oy7vm6p",
                {
                    name: name,
                    email: email,
                    phone: phone
                },
                "gXIRaBaHS460y8BBP"
            )
            .then(
                (response) => {
                    //console.log("Email sent:", response)
                    //alert("Email успешно отправился!")
                    setShow(false)
                },
                (error) => {
                    //console.error("Error sending email:", error)
                    alert("Email не отправился. Попробуйте позднее.")
                }
            );
    };

    return (
        <Modal centered dialogClassName="" show={show} onHide={handleClose}>
            <Modal.Header>
                <h6 className='text-center sans-bold'>Оставьте заявку на звонок и мы вам перезвоним!</h6>
            </Modal.Header>
            <Modal.Body className={'m-0 p-0 overflow-y-auto mh-300 d-flex flex-column'}>
                <Form.Control className='w-75 my-2 mx-3' type="text" placeholder="Имя" value={name} onChange={(e)=>setName(e.target.value)}/>
                <InputMask
                    mask='+7 (999) 999-99-99'
                    placeholder="Телефон"
                    className='w-75 my-2 mx-3 form-control'
                    value={phone}
                    onChange={e=>setPhone(e.target.value)}>
                </InputMask>
                <Form.Control className='w-75 my-2 mx-3' type="text" placeholder="Email (не обязательно)" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                <Form.Check className=' my-2 mx-3' checked={permission} label='Даю согласие на обработку персональных данных' onChange={e => setPermission(e.target.checked)} type="checkbox"/>

                <Button variant='primary'
                        className="my-3 w-120 mx-auto"
                        onClick={() => onSubmit()}
                >
                    ОТПРАВИТЬ
                </Button>
            </Modal.Body>
        </Modal>
    );
}

export default OrderWindow;