import React from 'react';

const Career = () => {
    return (
        <div id='career' className='bg-white px-4 pb-4'>

            <div className='d-flex justify-content-between flex-md-row flex-column'>
                <div>
                    <h4 className='text-primary'>КАРЬЕРА</h4>
                    <p>
                        Актуальный список вакансий доступен на сайте hh.ru в <a target="_blank" href='https://samara.hh.ru/search/vacancy?from=employerPage&employer_id=10254972&hhtmFrom=employer' className='text-primary link-dark link-underline-opacity-0'>
                        профиле нашей компании
                        </a><br/>Откликайтесь!
                    </p>

                    <div className='d-flex justify-content-start flex-md-row flex-column'>
                        <div>
                            <a href='mailto:rabota@ntc-ea.ru'><img src={process.env.PUBLIC_URL+`/career/email.png`} className='w-30'/></a>
                            <p className='my-1'>rabota@ntc-ea.ru</p>
                        </div>

                        <div className='m-0 mx-md-5'>
                            <a href='https://t.me/+79651354954'><img src={process.env.PUBLIC_URL+`/career/telegram.png`} alt='telegram' className='w-30'/></a>
                            <a href='viber://chat?number=%2B79651354954'><img src={process.env.PUBLIC_URL+`/career/viber.png`} className='w-30 mx-3'/></a>
                            <a href='https://api.whatsapp.com/send?phone=79651354954'><img src={process.env.PUBLIC_URL+`/career/whatsup.png`} className='w-30'/></a>
                            <p className='my-1'>+7 (987) 816-46-72</p>
                        </div>
                    </div>
                </div>

                <div>
                    <h4 className='text-primary'>КАК НАС НАЙТИ</h4>
                    <iframe className='w-md-750' height='300px' src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac85b16db0204a2e5a8d477b9eb3f987632d1b60d89c2af627f8fe817c0e8e6cd&amp;source=constructor"/>
                </div>
            </div>
        </div>
    );
};

export default Career;